import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USER_OFFERS, ADMIN_USER_OFFERS_CREATE } from 'Consts/routes';
import { API_RESOURCE_USER } from 'Consts/apiResources';

import { withVariables } from 'Utils/string';
import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { getFullName as getUserFullName } from 'Utils/user';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/admin/userOffers/Editor';
import { TAB_USER_OFFERS } from 'Components/pages/admin/UserOffers/component';

export default class AdminUserOffersCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            getSingleUser: PropTypes.func.isRequired,
        }),
    };

    static defaultProps = {};

    state = {
        predefinedState: {},
    }

    defaultQuery = {
        userId: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        const { predefinedState } = this.state;
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        if (queryObject.userId) {
            actions.getSingleUser({ id: queryObject.userId })
                .then((response) => {
                    const result = response.payload[API_RESOURCE_USER];

                    this.setState({
                        predefinedState: {
                            ...predefinedState,
                            userId: {
                                label: getUserFullName(result).label,
                                value: result.id,
                            },
                        },
                    });
                });
        }
    }

    render() {
        const { predefinedState } = this.state;
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="admin-user-offers-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_USER_OFFERS.path),
                        label: 'Zamówienia',
                    }, {
                        to: withVariables(ADMIN_USER_OFFERS.path, {}, { tab: TAB_USER_OFFERS }),
                        label: 'Lista zamówień',
                    }, {
                        to: withVariables(ADMIN_USER_OFFERS_CREATE.path),
                        label: 'Nowe zamówienie',
                    }]}
                >
                    <ViewHeader
                        title="Nowe zamówienie"
                    />
                    <Editor
                        location={location}
                        history={history}
                        predefinedState={{
                            userId: predefinedState.userId || undefined,
                        }}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}